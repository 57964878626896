import Image from 'next/image'
import { useIntersectionObserver } from 'src/common/hooks/useIntersectionObserver'
import { useAnalytics } from 'src/common/analytics'
import ButtonLink from 'src/components/shared/ButtonLink'

const TrustMarkers = ({ trustMarkersCombination }) => {
  const { track } = useAnalytics()
  const { ref } = useIntersectionObserver({
    threshold: 0.5,
    onChange: (isIntersecting) => {
      if (!isIntersecting) return
      track(
        {
          additional_info: {
            image: 'trust-marker-' + trustMarkersCombination.id,
          },
          event_type: 'track',
        },
        {},
        'section_view'
      )
    },
    freezeOnceVisible: true,
  })

  const handleClick = ({ trustMarker, i }) => {
    track(
      {
        event_type: 'track',
        click_id: trustMarker.id,
        click_position: i,
        click_type: 'Link Click',
        additional_info: {
          section: 'trust-marker-' + trustMarker.id,
          click_url: trustMarker.link,
        },
      },
      {},
      'button_click'
    )
  }

  return (
    <div
      ref={ref}
      className="flex flex-col items-center justify-center py-6 lg:flex-row"
    >
      {trustMarkersCombination?.trustMarker.map((trustMarker, i) => (
        <div
          key={trustMarker.fields.id}
          className="flex w-full flex-col lg:w-fit lg:flex-row"
        >
          {i > 0 && (
            <div className="mx-12 hidden h-25 w-[1.5px] bg-[#BEC6D5] lg:block" />
          )}
          {i > 0 && (
            <div className="my-6 block h-[1.5px] w-full bg-[#BEC6D5] lg:hidden" />
          )}
          <ButtonLink
            href={trustMarker.fields.link}
            className="relative flex items-center justify-center"
            disabled={!trustMarker.fields.link}
            onClick={() => handleClick({ i, trustMarker: trustMarker.fields })}
            target="blank"
          >
            <Image
              src={'https:' + trustMarker.fields.image.fields.file.url}
              alt={trustMarker.fields.alt}
              width={300}
              height={300}
              className="h-20 object-contain"
            />
          </ButtonLink>
        </div>
      ))}
    </div>
  )
}

export default TrustMarkers
